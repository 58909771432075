import React, {useState} from 'react';
import './App.css';
import logo from './images/logo-epson.jpg';

const App = () => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);


    const questions = [
        {
            id: 0,
            text: 'Is you looking for information about a service intervention ?',
            followUpYes: 34,
            followUpNo: 36,
            return: null
        },

        {
            id: 1,
            text: 'Will you do the intervention yourself?',
            followUpYes: 2,
            followUpNo: 14,
            return: 0
        },
        {
            id: 2,
            text: 'Are you looking for spare parts?',
            followUpYes: 3,
            followUpNo: 6,
            return: 1
        },
        {
            id: 3,
            text: 'Are you looking for the spare parts list?',
            followUpYes: 4,
            followUpNo: 5,
            return: 1
        },
        {
            id: 4,
            text: 'You can find them on the link below',
            followUpYes: null,
            followUpNo: null,
            link: 'https://spi.epson.net/techexchange/spi/Login',
            next: 5,
            return: null
        },
        {
            id: 5,
            text: 'Order your spare parts at Gedat-spareparts. You can later claim them in SAP Fiori',
            followUpYes: null,
            followUpNo: null,
            link: "",
            next: 6
        },
        {
            id: 6,
            text: 'You need a service manual?',
            followUpYes: 7,
            followUpNo: 8,
            return: null
        },
        {
            id: 7,
            text: 'You can find them on our TechExchange. You can find the link below',
            followUpYes: 7,
            followUpNo: 8,
            link: 'https://support2.epson.net/esup_v3/Login.do',
            next: 8
        },
        {
            id: 8,
            text: 'You need assistance from our 2nd Level Team?',
            followUpYes: 9,
            followUpNo: 12,
            link: null,
            next: null
        },
        {
            id: 9,
            text: 'Is it urgent?',
            followUpYes: 10,
            followUpNo: 11,
            link: null,
            next: null
        },
        {
            id: 10,
            text: 'Call them on 0049/2159 5381 023',
            followUpYes: null,
            followUpNo: null,
            link: null,
            next: 12
        },
        {
            id: 11,
            text: 'Create a technical escalation on or TE MSD Portal',
            followUpYes: null,
            followUpNo: null,
            link: 'https://csmte.epson.eu',
            next: 12
        },
        {
            id: 12,
            text: 'Repair the product and claim all parts (and labour if applicable) in Fiori',
            link: 'https://eapps.epson.eu/',
            followUpYes: null,
            followUpNo: null,

            next: 13
        },
        {
            id: 13,
            text: 'Finish',
            followUpYes: null,
            followUpNo: null,
            next: null
        },
        {
            id: 14,
            text: "Cases should be created in our MSD Portal and then be sent to MService so please don't contact MService directly!\n Do you want to create the case yourself or do you need to contact our helpdesk?",
            followUpYes: 15,
            followUpNo: 16,
            textYes: 'I create it',
            textNo: 'Helpdesk',
            next: null
        },
        {
            id: 15,
            text: 'Go to our CSM App and create the ticket.',
            followUpYes: 13,
            followUpNo: 29,
            next: null,
            textYes: "OK",
            textNo: "I don't have credentials",
            link: "https://csmapp.azurewebsites.net"
        },
        {
            id: 16,
            text: 'Do you want to send an email or call them?',
            followUpYes: 17,
            followUpNo: 18,
            textYes: "Email",
            textNo: "Call",
            next: null
        },
        {
            id: 17,
            text: 'Will you use the PDF Sheet? ',
            followUpYes: 19,
            followUpNo: 20,
            next: null
        },
        {
            id: 18,
            text: 'You can call our helpdesk on 02/792.04.48 (Reseller) or 02/792.04.47 (end-user)',
            followUpYes: null,
            followUpNo: null,
            next: 13
        },
        {
            id: 19,
            text: 'Fill in this PDF sheet. Please provide all necessary details and which troubleshooting has already been done.\n Is it device with a different warranty (EPP or Coverplus attached)?',
            followUpYes: 22,
            followUpNo: 23,
            next: null
        },
        {
            id: 20,
            text: 'Send an email to business.supportnl@epson.be\nPlease provide the following information\n1) Serial number\n2) Contact person (name, email and phone)\n3) Address\n4) Purchase date (attach the invoice if possible)\n5) Problem description, please be as accurate as possible and include any troubleshooting that has already been done',
            followUpYes: null,
            followUpNo: null,
            next: 13
        },
        {
            id: 21,
            text: 'You can contact a service partner by choice.\n\nHowever, we recommend contacting MService as they have the most experience.',
            followUpYes: null,
            followUpNo: null,
            link: 'https://mservice.nl/',
            next: 13
        },
        {
            id: 22,
            text: 'Send the sheet to epsonepp@epson.be',
            followUpYes: null,
            followUpNo: null,
            next: 13
        },
        {
            id: 23,
            text: 'Send the sheet to business.supportnl@epson.be',
            followUpYes: null,
            followUpNo: null,
            next: 13
        },
        {
            id: 24,
            text: 'Is the machine within Warranty ?',
            followUpYes: 1,
            followUpNo: 21,
            textNext: "I'm not sure",
            next: 33,
            return: null
        },
        {
            id: 25,
            text: 'Are you looking for our Neon Partner Portal?',
            followUpYes: 26,
            followUpNo: 27,
            return: null
        },
        {
            id: 26,
            text: 'Here you can find it.',
            followUpYes: null,
            followUpNo: null,
            next: 13,
            link: 'https://neon.epson-europe.com/'
        },
        {
            id: 27,
            text: 'Are you looking for our Training @ Epson portal?',
            followUpYes: 28,
            followUpNo: 30,
            return: null
        },
        {
            id: 28,
            text: 'Here you can find it.',
            followUpYes: null,
            followUpNo: null,
            next: 13,
            link: 'https://training.epson.eu/'
        },
        {
            id: 29,
            text: 'Contact your local Presales and let him create an account',
            followUpYes: null,
            followUpNo: null,
            next: 13,
        },
        {
            id: 30,
            text: 'Are you looking for our ERS Portal?',
            followUpYes: 31,
            followUpNo: 32,
            return: null
        },
        {
            id: 31,
            text: 'Here it is.',
            followUpYes: null,
            followUpNo: null,
            next: 13,
            link: 'https://www.remote-services.epson.com/'
        },
        {
            id: 32,
            text: 'Please contact your local presales to ask your question.',
            followUpYes: null,
            followUpNo: null,
            next: 13,
            return: null
        },
        {
            id: 33,
            text: 'You can check the warranty status on the website below.\n\nHINT: If you chose France as the country, you can see the start and end date',
            followUpYes: null,
            followUpNo: null,
            link: 'https://warrantycheck.epson.eu',
            next: 24,
            return: null
        },
        {
            id: 34,
            text: 'Do you want te create a new service intervention or are you looking for information about an existing service intervention?',
            followUpYes: 24,
            followUpNo: 35,
            textYes: 'New',
            textNo: 'Existing',
            next: null,
            return: null
        },
        {
            id: 35,
            text: 'You can check the latest status on the website of MService.\nPlease use the case number if possible (BE-XXXXXXXXXX)',
            followUpYes: null,
            followUpNo: null,
            link: 'https://asbwd.mservice.nl/status/index.php',
            next: 13,
            return: null
        },
        {
            id: 36,
            text: 'Do you want to register a DOA?',
            followUpYes: 37,
            followUpNo: 25,
            next: null,
        },
        {
            id: 36,
            text: 'You can create a DOA on the website of MService.\nPlease be sure to include everything (cables, accessoires)!',
            followUpYes: null,
            followUpNo: null,
            link: 'https://asbwd.mservice.nl/doa/index.php?-lang=nl',
            next: 13,
            return: null
        },
    ];

    const handleAnswer = (answer) => {
        setAnswers([...answers, answer]);

        if (answer === 'start') {
            setQuestionIndex(0);
        } else if (answer === 'yes') {
            setQuestionIndex(questions[questionIndex].followUpYes);
        } else if (answer === 'no') {
            setQuestionIndex(questions[questionIndex].followUpNo);
        } else {
            setQuestionIndex(questions[questionIndex].next);
        }
    };

    return (
        <div className="app">
            <img src={logo} alt="Logo Epson"/>
            {questionIndex < questions.length && (questions[questionIndex].next || questions[questionIndex].followUpNo || questions[questionIndex].followUpYes) ? (
                <div>
                    {/*Index: {questionIndex}, maximum : {questions.length}*/}

                    {questions[questionIndex].text.split("\n").map((value, index) => {
                        return (
                            <p key={index}>
                                {value}
                            </p>
                        );
                    })
                    }
                    {questions[questionIndex].link ?
                        <p><a target='_new' href={questions[questionIndex].link}>{questions[questionIndex].link}</a></p>
                        : <></>
                    }


                    {questions[questionIndex].followUpYes ?
                        <button className="answerButton" onClick={() => handleAnswer('yes')}>
                            {questions[questionIndex].textYes ? questions[questionIndex].textYes : "Yes"}
                        </button> :
                        <></>
                    }
                    {questions[questionIndex].followUpNo ?
                        <button className="answerButton" onClick={() => handleAnswer('no')}>
                            {questions[questionIndex].textNo ? questions[questionIndex].textNo : "No"}
                        </button> :
                        <></>
                    }
                    {questions[questionIndex].next ?
                        <button className="answerButton" onClick={() => handleAnswer('next')}>
                            {questions[questionIndex].textNext ? questions[questionIndex].textNext : "Next"}
                        </button> :
                        <></>
                    }
                </div>
            ) : (
                <>
                    <p className="endMessage">You are finished, thank you!</p>
                    <button className="answerButton" onClick={() => handleAnswer('start')}>
                        Start again
                    </button>
                </>
            )}
        </div>
    );
};

export default App;
